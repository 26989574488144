// external imports
import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"

// helpers
import { up, addResponsivity } from "../lib/styles"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
} from "../lib/helpers"
import { applyLang, applyLangSingle } from "../lib/intl"
import injectIntl from "../lib/intl/injectIntl"

// components
import T from "../lib/intl/T"
import IntlContext from "../lib/intl/IntlContext"
import {
  Title,
  SEO,
  Gap,
  Row,
  Col,
  Link,
  Text,
  RH,
  Listing,
  EstheticonIframe,
  EstheticonWidget,
  TestimonialsSection,
} from "../components"
import ProcedureItem from "../components/ProcedureItem"

// assets
// import heroImage from '../data/images/logo.png'

// custom styled components
const Iframe = styled.iframe`
  margin: 0px;
  overflow: hidden;
  width: 100%;
  visibility: visible;
  opacity: 1;
`

class References extends React.Component {
  render() {
    const {
      location: { pathname },
      intl: { language, t },
      data,
    } = this.props
    const { testimonials } = extractNodesFromDataApplyLangs(data, language)
    console.log("language", language === "cs", language)
    return (
      <>
        <SEO
          title={t("references - meta title")}
          description={t("references - meta desc")}
          pathname={pathname}
        />
        <Gap gap="32px" mobileGap="25px" />
        <Title.PageTitle display="none">
          <T>Recenze</T>
        </Title.PageTitle>
        <Col>
          <TestimonialsSection
            data={testimonials}
            showMoreReferencesLink={false}
          />
          <Gap gap="60px" mobileGap="40px" />
          {(language === "cs" && <EstheticonWidget />) || (
            <EstheticonIframe language={language} eid={207898} />
          )}
          <Gap gap="60px" mobileGap="40px" />
        </Col>
      </>
    )
  }
}

export default injectIntl(References)

export const pageQuery = graphql`
  query {
    testimonials: allTestimonials(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          ...TestimonialFragment
        }
      }
    }
  }
`
